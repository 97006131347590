import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image/withIEPolyfill';

const CardImage = props => {
  let normalizedProps = {
    ...props,
    objectFit: 'cover',
    objectPosition: '50% 0'
  };

  return (
    <StaticQuery
      query={graphql`
        query {
          allFile(filter: { extension: { regex: "/jpeg|jpg|png|gif/" } }) {
            edges {
              node {
                extension
                relativePath
                childImageSharp {
                  fluid(
                    maxWidth: 1920,
                    srcSetBreakpoints: [400, 600, 960, 1280, 1920],
                    sizes: "(max-width: 1920px) 100vw, 1920px"
                  ) {
                    base64
                    aspectRatio
                    src
                    srcSet
                    sizes
                  }
                }
              }
            }
          }
        }
      `}
      render={({ allFile: images }) => {
        const image = images.edges.find(
          image => image.node.relativePath === props.relativePath
        );

          if (!image) return null;

          return <Img {...normalizedProps}  fluid={image.node.childImageSharp.fluid} />;
      }}
    />
  );
};

export default CardImage;