import React from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

//Images
import { OurServicesImages } from '../imageURLs';

import SEO from '../components/SEO';
import { withQuoteWizard } from '../components/withQuoteWizard';
import CtaBlock from '../components/CtaBlock';
import CenterContent from '../components/CenterContent';
import CTAButton from '../components/CTAButton/CTAButton';
import InnerImage from '../components/InnerImage/innerImage';
import ServiceCard from '../components/OurServices/ServiceCard';
import ServiceIntroSection from '../components/ServiceIntroSection';

const styles = theme =>
  createStyles({

    cardsWrap: {
      [theme.breakpoints.up(767)]: { 
        flexDirection: "row"
      },
      [theme.breakpoints.up(960)]: { 
        marginTop: 30,
      },
    },

    img: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },

  });

const OurServicesPage = ({classes,toggleQuoteComp,children}) => (

<React.Fragment>

  <SEO
    title="Our Services - Twin View Turf - Producer of Australia’s Best Lawns"
    keywords={[
      `Turf care guides`,
      `care guides`,
      `Turf`,
      `Lawns`,
      `Best Lawns`,
      `Australia’s Best Lawns`,
      `lawn producer`,
    ]}
  />

    {/* HERO SECTION START */}
      <InnerImage 
        image={{className: classes.img, src: OurServicesImages.hero}}
    ></InnerImage>

    {/* SERVICES - INTRO SECTION */}
    <ServiceIntroSection
      title="Our Services"
      subTitle="Complete project solutions."
      text="We are leaders in sports field construction with the expertise and machinery to deliver tailored solutions for any project."
    ></ServiceIntroSection>

    <CenterContent style={{ maxWidth: '1380px' }}>
      <Grid
        container
        direction="column"
        className={classes.cardsWrap}
      >

        <ServiceCard
          cardImage={OurServicesImages.cardImg1}
          ctaTitle= "Sports Field Construction"
          ctaText="Learn more"
          ctaHref="/our-projects"
        ></ServiceCard>

        <ServiceCard
          cardImage={OurServicesImages.cardImg2}
          ctaTitle= "Drainage & Irrigation"
          ctaText="Learn more"
          ctaHref="/drainage"
        ></ServiceCard>

        <ServiceCard
          cardImage={OurServicesImages.cardImg3}
          ctaTitle= "Sports Turf"
          ctaText="Learn more"
          ctaHref="/sports-turf"
        ></ServiceCard>

        <ServiceCard
          cardImage={OurServicesImages.cardImg4}
          ctaTitle= "Renovation and Maintenance"
          ctaText="Learn more"
          ctaHref="/renovation"
        ></ServiceCard>

        <ServiceCard
          cardImage={OurServicesImages.cardImg5}
          ctaTitle= "Surface Preparation"
          ctaText="Learn more"
          ctaHref="/surface-preparation"
        ></ServiceCard>

        <ServiceCard
          cardImage={OurServicesImages.cardImg6}
          ctaTitle= "Project Consultation"
          ctaText="Learn more"
          ctaHref="/project-consultation"
        ></ServiceCard>

      </Grid>
    </CenterContent>

      {/* CTA SECTION START */}
    <CtaBlock title="Planning a field construction?">
      <CTAButton
        inverted
        ctaHref="/contact"
        ctaText="Get a quote"
      />
    </CtaBlock>

  {children}
</React.Fragment>

)

export default withQuoteWizard(withStyles(styles, { withTheme: true })(OurServicesPage));