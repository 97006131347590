import React from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import CardImage from '../CardImage';
import CTAButton from '../CTAButton/CTAButton';

const styles = theme =>
  createStyles({

    cardWrap: {
      position: 'relative',
      [theme.breakpoints.up(960)]: {
        flex: '0 0 50%',
        maxWidth: '50%',
        marginBottom: 30,
        padding: '0 15px'
      },

      [theme.breakpoints.up(1450)]: {
        padding: '0 30px',
        marginBottom: 60,
      },
    },

    cardImageWrap: {
      position: 'relative',
      zIndex: 1,
      top: '0',
      left: '0',
    },

    cardImage: {
        height: '430px',
        [theme.breakpoints.up(767)]: {
          height: '530px'
        },
    },

    ctaWrap: {
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: '15px 10px',
        [theme.breakpoints.up(410)]: {
          flexDirection: "row",
          textAlign: "center",
        },
        [theme.breakpoints.up(960)]: {
          textAlign: "center",
          padding: "25px 15px"
        },
      },

      cardContent: {
        backgroundColor: theme.palette.primary.main,
        height: '100%',
      },

      ctaTitle: {
        fontSize: 20,
        textAlign: 'left',
        fontWeight: '300',
        paddingBottom: 10,
        [theme.breakpoints.up(410)]: {
          maxWidth: '100%',
          paddingRight: '25px',
          paddingBottom: 0, 
        },
        [theme.breakpoints.up(960)]: { 
          maxWidth: '220px',
          fontSize: 24,
          // fontWeight: '300',
        },
        [theme.breakpoints.up(1200)]: { 
          maxWidth: '100%',
          paddingBottom: '4px'
        },
        [theme.breakpoints.up(1450)]: {
          fontSize: 32,
          letterSpacing: '-0.3px',
          paddingBottom: '4px'
        },
      },

  });

  const ServiceCard = ({classes,cardImage,ctaTitle,ctaText,ctaHref}) => (

    <Grid
    container  
    direction="column"
    className= {classes.cardWrap}
>

    <Grid className={classes.cardContent} >

    {/* CardIMG */}
    {cardImage && (
        <Grid item className={classes.cardImageWrap}>
            <CardImage relativePath={cardImage} className={classes.cardImage}></CardImage>
        </Grid>
    )}

        {/* CardCTA  */}
         <Grid
            container
            className ={classes.ctaWrap}
        >

            <Typography
            variant="body2"
            color="secondary"
            className={classes.ctaTitle}
            > 
            {ctaTitle} 
            </Typography>

            <CTAButton
                ctaText={ctaText}
                ctaHref={ctaHref}
                className={classes.ctaButton}
                tertiary
            >
            </CTAButton>

        </Grid>

    </Grid>
   
    </Grid>


  )
  export default withStyles(styles, { withTheme: true })(ServiceCard);